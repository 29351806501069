import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { MeetingDate, ListComment, CommentCollection } from '../../types';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CommentRow from './CommentRow';
import { useCommentsListContext } from '../../../../hooks/CommentsListContext';
import StarIcon from '@material-ui/icons/Star';

export interface ListCommentsProps {
  comments: CommentCollection
}

interface RenderBySystemProps {
  title: string;
  comments?: MeetingDate[],
  openCase: (comment: ListComment) => any;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  arrow: {
    fontSize: '1.9rem !important'
  },
  title: {
    fontSize: '1.375rem'
  },
  level1: {
    fontSize: '1.25rem'
  },
  level2: {
    fontSize: '1.125rem'
  },
  wrapper: {
    display: 'flex'
  },
  unread: {
    marginTop: '6px',
    marginLeft: '-17px'
  }
}));

const getIcon = (unread:number|boolean, classes:any) => {
  if (unread) {
    return (<div className={classes.wrapper}><StarIcon className={classes.unread} color={'error'}/><ArrowRightIcon className={classes.arrow} /></div>);
  }
  return (<ArrowRightIcon className={classes.arrow} />);
}

const RenderBySystem = (props: RenderBySystemProps) => {
  const classes = useStyles();
  const { title, comments, openCase } = props;
  const key = title.substring(0,1);

  if (comments && comments.length > 0) {
    return (
      <TreeItem
        nodeId={title}
        expandIcon={getIcon(true, classes)}
        label={<Typography className={classes.title}>{title}</Typography>}
      >
        {comments.map((meetingDate, idx) => {
          const label = meetingDate.meetingDate ? meetingDate.meetingDate : 'Inget datum fastställt';
          return (
            <TreeItem
              key={idx}
              nodeId={`${key}${label}`}
              expandIcon={getIcon(meetingDate.unread, classes)}
              label={<Typography className={classes.level1}>{label}</Typography>}
            >
              {meetingDate.caseNoComments.map((caseNo, idx) => {
                return (
                  <TreeItem
                    key={idx}
                    nodeId={caseNo.caseno}
                    expandIcon={getIcon(caseNo.unread, classes)}
                    label={<Typography className={classes.level2}>{caseNo.caseno}</Typography>}
                  >
                    {caseNo.comments.map((comment, idx) => (
                      <CommentRow
                        key={idx}
                        comment={comment}
                        openCase={openCase}
                        isSingle={caseNo.comments.length <= 1}
                      />
                    ))}                    
                  </TreeItem>
                )
              })}
            </TreeItem>
          )})
        }
      </TreeItem>
    );
  }
  return (null);
};

const CommentsList = (props: ListCommentsProps) => {
  const { state, setState } = useCommentsListContext();
  const { comments } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setState(preState=>({
      ...preState,
      expanded: nodeIds
    }));
  };

  const openCase = (comment: ListComment) => {
    if (comment.type === 'Case') {
      history.push(`/case/${comment.caseno}`);
    } else {
      history.push(`/case/${comment.caseno}#${comment.unid}`);
    }
  };

  useEffect(() => {
    if (state.expanded.length === 0) {
      setState(preState=>({
        ...preState,
        expanded: ['Patientskadenämnden', 'Läkemedelsskadenämnden']
      }));
    }
  // eslint-disable-next-line
  }, []);

  if ((comments.pads && comments.pads.length > 0) || (comments.lads && comments.lads.length > 0)) {
    return (
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon className={classes.arrow} />}
        expanded={state.expanded}
        selected={[]}
        onNodeToggle={handleToggle}
      >
        <RenderBySystem
          title={'Patientskadenämnden'}
          comments={comments.pads}
          openCase={openCase}
        />
        <RenderBySystem
          title={'Läkemedelsskadenämnden'}
          comments={comments.lads}
          openCase={openCase}
        />
      </TreeView>
    );
  }
  return (
    <Typography className={classes.level2}>För tillfället har Ni inga aktiva ärenden i systemet.</Typography>
  );
};

export default CommentsList;
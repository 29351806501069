import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import createPurify from 'dompurify';
import { CaseAction, Comment, SaveCommentActionTypes, SaveComment } from '../types';

const saveComment = async (saveComment: SaveComment, dispatch: Dispatch<CaseAction>) => {
  try {
    dispatch({type: SaveCommentActionTypes.START});

    let response: AxiosResponse ;
    if (saveComment.unid) {
      response = await axios.put(`/api/comments`, saveComment);
    } else {
      response = await axios.post(`/api/comments`, saveComment);
    }

    dispatch({
      type: SaveCommentActionTypes.DONE, 
      payload: purifyComments(response.data.comments) as Comment[]
    });
    return true;
  } catch (error) {
    dispatch({type: SaveCommentActionTypes.ERROR, payload: error.message});
  }
  return false;
}

const purify = createPurify();
const purifyComments = (comments: Comment[]): Comment[] => {
  return comments.map(comment => {
    return {
      ...comment,
      created: new Date(comment.created),
      updated: new Date(comment.updated),
      comment: purify.sanitize(comment.comment)
    } as Comment;
  });
}

export default saveComment;
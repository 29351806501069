import React, { Fragment, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { useAuthenticatedContext } from '../../../hooks/AuthenticatedContext';
import { Authenticated } from '../types';
import RenderIf from 'components/general/renderIf/RenderIf';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      '@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)':
        {
          padding: '0 0 0 4px',
        },
    },
    label: {
      fontFamily: '"Times New Roman", Times, serif',
      '@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)':
        {
          fontSize: '1.4rem',
        },
    },
    grow: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
    },
    linkList: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      '& ul': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        listStyle: 'none',
        padding: 0,
        '& li': {
          paddingLeft: theme.spacing(1),
          borderLeft:
            '1px solid ' +
            theme.palette.getContrastText(theme.palette.primary.main),
          '&:first-child': {
            borderLeft: 'none',
            paddingRight: theme.spacing(1),
            paddingLeft: 0,
          },
        },
      },
      '@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)':
        {
          '& ul': {
            flexDirection: 'column',
            '& li': {
              paddingLeft: '0 !important',
              borderLeft: 'none !important',
            },
          },
        },
    },
    link: {
      fontSize: '16px',
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  })
);

export const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { authenticated, setAuthenticated } = useAuthenticatedContext();
  const name = authenticated.isAuthenticated
    ? `${authenticated.firstName} ${authenticated.lastName}`.trim()
    : 'Ej inloggad';

  const logout = () => {
    setAuthenticated({} as Authenticated);
  };

  const openUserAdmin = () => {
    history.push('/users');
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky">
      <Toolbar className={classes.toolbar}>
        <Typography variant="h4" component="h1" className={classes.label}>
          Webbportal för medicinskt sakkunniga
        </Typography>
        <div className={classes.linkList}>
          <ul>
            <li>
              <Link to={'/about'} className={classes.link}>
                {'Om portalen'}
              </Link>
            </li>
          </ul>
        </div>
        {authenticated.isAuthenticated ? (
          <Fragment>
            <Typography>{name}</Typography>
            <div>
              <IconButton
                aria-label="menu"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="user-menu"
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={logout}>Logga ut</MenuItem>
                <RenderIf doRender={authenticated.isAdmin}>
                  <MenuItem onClick={openUserAdmin}>Hantera användare</MenuItem>
                </RenderIf>
              </Menu>
            </div>
          </Fragment>
        ) : (
          <Typography>Ej inloggad</Typography>
        )}
      </Toolbar>
    </AppBar>
  );
};

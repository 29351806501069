import { MessageBarProps } from '../general/messageBar/MessageBar';
export interface Comment {
  unid: string;
  type: string;
  caseno: string;
  responsible: string;
  initials: string;
  meetingDate: string;
  created: Date;
  updated: Date;
  chairDecision: boolean;
  myComment: boolean;
  comment: string;
}

export interface SaveComment {
  unid?: string;
  caseno: string;
  comment: string;
  done?: boolean;
}

export interface CommentsWrapper {
  caseno: string;
  comments: Comment[];
  pdf: string;
  unid: string;
  source: string;
}

export interface ICaseImage {
  dzi: boolean;
  dziReady: boolean;
  name: string;
  size: number;
}

export interface CaseState {
  loading: boolean;
  loadingError: boolean;
  loadingError401: boolean;
  saving: boolean;
  comments: Comment[];
  pdf?: string;
  message?: MessageBarProps;
  unid?: string;
  caseno?: string;
  images?: ICaseImage[];
}

export interface CaseAction {
  type: LoadCommentsActionTypes | SaveCommentActionTypes | string;
  payload?: any;
}

export enum LoadCommentsActionTypes {
  START = 'LOAD_COMMENTS_START',
  DONE = 'LOAD_COMMENTS_DONE',
  ERROR = 'LOAD_COMMENTS_ERROR',
}

export enum SaveCommentActionTypes {
  START = 'SAVE_COMMENT_START',
  DONE = 'SAVE_COMMENT_DONE',
  ERROR = 'SAVE_COMMENT_ERROR',
}

import axios from 'axios';
import { Dispatch } from 'react';
import { BankIDCheckResponse, LoginAction, LoginActionType, LoginState, Progress } from '../types';
import { checkLogin } from './checkLogin';

let pollingInterval: NodeJS.Timeout;
export const pollLoginStatus = async (dispatcher: Dispatch<LoginAction>) => {
  pollingInterval = setInterval(async () => {
    const result: BankIDCheckResponse = await checkLogin();

    if (result.progress === Progress.PENDING) {
      dispatcher({
        type: LoginActionType.LOGIN_PENDING,
        payload: { message: result.msg, qrCode: result.qrCode, hintCode: result.hintCode } as LoginState
      });
    } else {
      clearInterval(pollingInterval);
      if (result.progress === Progress.AUTHENTICATED) {
        sessionStorage.setItem('csrfToken', result.csrfToken ? result.csrfToken : '');
        axios.defaults.headers.common = {
          'X-CSRFToken': result.csrfToken || '',
        };
        dispatcher({
          type: LoginActionType.LOGIN_SUCCESS,
          payload: { user: result.user } as LoginState
        });
      } else {
        dispatcher({
          type: LoginActionType.LOGIN_ERROR,
          payload: { message: result.msg } as LoginState
        });
      }
    }
  }, 1000);
};

export const cancelPollLoginStatus = () => {
  clearInterval(pollingInterval);
};

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { MessageProps } from '../types';

export interface MessageBarProps extends MessageProps {
  onClose?: () => any;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  alert: {
    marginBottom: theme.spacing(2)
  }
}));

const MessageBar = (props: MessageBarProps) => {
  const classes = useStyles();
  if (props.message) {
    return (
      <Alert
        className={classes.alert}
        variant='filled'
        severity={props.status}
        action={ props.onClose ? (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={props.onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ) : null }
      >
        {props.message}
      </Alert>
    );
  }
  return (null);
};

export default MessageBar;
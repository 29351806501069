import React, { useReducer, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import UserTable from './parts/userlist/UserTable';

import UserDialog from './parts/userdialog/UserDialog';
import ConfirmDialog from '../general/confirmDialog/ConfirmDialog';
import MessageBar from '../general/messageBar/MessageBar';
import UserAdminReducer, { initialState } from './reducers';
import { User, CreateUserActionTypes, UpdateUserActionTypes, DeleteUserActionTypes } from './types';
import { createUser, deleteUser, loadUsers, updateUser } from './actions';
import Loading from '../general/loading/Loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1)
    },
    alert: {
      marginBottom: theme.spacing(2)
    },
    link: {
      color: theme.palette.text.primary,
      display: 'flex',
      textDecoration: 'none'
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    root: {
      display: 'flex',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    }
  }));

const UserAdmin = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(UserAdminReducer, initialState);

  useEffect(() => {
    loadUsers(dispatch);
  }, []);

  const createUserStart = () => {
    dispatch({type: CreateUserActionTypes.CREATE_USER_START});
  }

  const editUserStart = (user: User) => {
    dispatch({type: UpdateUserActionTypes.UPDATE_USER_START, payload: user});
  }

  const deleteUserStart = (user: User) => {
    dispatch({type: DeleteUserActionTypes.DELETE_USER_START, payload: user});
  }

  const saveUser = (user: User) => {
    if (user.id) {
      updateUser(dispatch, user);
    } else {
      createUser(dispatch, user);
    }
  }

  const closeDialog = () => {
    dispatch({type: 'USER_DIALOG_CLOSE'});
  }

  const closeMessageBar = () => {
    dispatch({type: 'ALERT_CLOSE'});
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            {'Startsidan'}
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            <PeopleIcon className={classes.icon} />
            {'Hantera användare'}
          </Typography>
        </Breadcrumbs>
      </div>
      <div className={classes.root}>
        <Typography variant="h5" component="h2" className={classes.title}>Användare</Typography>
        <Button onClick={createUserStart} variant="contained" color="primary" startIcon={<PersonAddIcon />}>
          {'Lägg till ny användare'}
        </Button>
      </div>
      <div className={classes.container}>
        <UserDialog
          show={state.dialog.show}
          user={state.dialog.user}
          userList={state.userList}
          onSave={saveUser}
          onClose={closeDialog}
        />
        { state.deleteDialog ? (
          <ConfirmDialog
            show={state.deleteDialog.show}
            title={'Ta bort användare'}
            content={state.deleteDialog.message}
            acceptTitle={'Ta bort'}
            cancelTitle={'Avbryt'}
            onAccept={()=>deleteUser(dispatch, state.deleteDialog!.user)}
            onCancel={()=>dispatch({type: DeleteUserActionTypes.DELETE_USER_CANCEL})}
          />
          ) : null
        }
        <MessageBar {...state.message} onClose={closeMessageBar} />
        <Loading loading={state.loading} hide={false}>
          <UserTable
            userList={state.userList}
            editUser={editUserStart}
            deleteUser={deleteUserStart}
          />
        </Loading>
      </div>
    </Fragment>
  );

}

export default UserAdmin;
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Backdrop } from '@material-ui/core';

interface SavingProps {
  saving: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const Saving = (props: SavingProps) => {
  const classes = useStyles();
  const [ showSaving, setShowSaving ] = React.useState<boolean>(false);
  const { saving } = props;

  React.useEffect(()=>{
    if (saving) {
      const timeout = setTimeout(()=> {
        setShowSaving(true);
      }, 1000);
      return ()=> {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    } else {
      setShowSaving(false);
    }
  }, [saving]);

  return (
    <Backdrop className={classes.backdrop} open={saving} invisible={!showSaving}>
      <Typography variant={'button'} gutterBottom>{'Sparar'}</Typography>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Saving;


import { LoadUsersActionTypes, User, UserAdminState, UserAdminAction } from '../types';
import { MessageBarProps } from '../../general/messageBar/MessageBar';

const loadUsersReducer = (state: UserAdminState, action: UserAdminAction) => {
  switch (action.type) {
    case LoadUsersActionTypes.LOAD_USERS_START:
      return {
        ...state,
        userList: [] as User[],
        loading: true
      }
    case LoadUsersActionTypes.LOAD_USERS_DONE:
      return {
        ...state,
        userList: action.payload as User[],
        loading: false
      }
    case LoadUsersActionTypes.LOAD_USERS_ERROR:
      return {
        ...state,
        userList: [] as User[],
        loading: false,
        message: {
          status: 'error',
          message: action.payload
        } as MessageBarProps
      }
  }
  return undefined;
}

export default loadUsersReducer;
import expect from '@edgeguideab/expect';
import { User } from '../types';

function validateUser(user: User) {
  return expect({
    firstName: {
      type: 'string',
      blockUnsafe: true,
      strictEntities: true,
      allowed: ['\''],
      allowNullErrorCode: 'Förnamn får ej vara tomt',
      errorCode: 'Förnamnet innehåller olämpliga tecken'
    },
    lastName: {
      type: 'string',
      blockUnsafe: true,
      strictEntities: true,
      allowed: ['\''],
      allowNullErrorCode: 'Efternamn får ej vara tomt',
      errorCode: 'Efternamnet innehåller olämpliga tecken'
    },
    email: {
      type: 'email',
      allowNull: true,
      errorCode: 'Epost adressen är ej korrekt'
    },
    pin: {
      type: 'identityNumber',
      allowNullErrorCode: 'Personnummer får ej vara tomt',
      errorCode: 'Felaktigt personnummer'
    }
  }, user);
}

export default validateUser;
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { LoginAction, LoginActionType, LoginState, LoginType } from "../types";
import { pollLoginStatus } from './pollLoginStatus';

export const startLogin = async (loginType: LoginType, dispatch: Dispatch<LoginAction>): Promise<boolean> => {
  try {
    const response: AxiosResponse = await axios.post(`/api/sessions/login`, {}, { withCredentials: true });
    dispatch({
      type: LoginActionType.LOGIN_START,
      payload: {
        autoStartToken:  response.data.autoStartToken,
        message: response.data.msg,
        loginType
      } as LoginState
    });
    pollLoginStatus(dispatch);
    return true;
  } catch (e) {
    let message = 'Ett oväntat fel inträffade';
    if (e instanceof AxiosError) {
      if (!e.response || e.response.status === 502) {
        message = 'BankID tjänsten svarar inte';
      } else {
        if (e.response.data?.msg) {
          message = e.response.data.msg;
        }
      }
    }
    dispatch({
      type: LoginActionType.LOGIN_ERROR,
      payload: { message } as LoginState
    });
    console.error(e);
    return false;
  }
};
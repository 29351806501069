import React, { useReducer, useEffect, Fragment } from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MyCasesReducer, { initialState } from './reducers/MyCasesReducer';
import loadComments from './actions/loadComments';
import CommentsList from './parts/commentslist/CommentsList';
import MessageBar from '../general/messageBar/MessageBar';
import Loading from '../general/loading/Loading';
import { useAuthenticatedContext } from '../../hooks/AuthenticatedContext';
import { Authenticated } from '../general/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1)
    },
    link: {
      color: theme.palette.text.primary,
      display: 'flex',
      textDecoration: 'none'
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    root: {
      display: 'flex',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    }
  }));

const MyCases = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(MyCasesReducer, initialState);
  const { setAuthenticated } = useAuthenticatedContext();

  useEffect(()=> {
    loadComments(dispatch);
  }, []);

  useEffect(()=> {
    if (state.loadingError401) {
      setAuthenticated({} as Authenticated);
    }
  // eslint-disable-next-line
  }, [state.loadingError401]);

  const closeMessageBar = () => {
    dispatch({type: 'ALERT_CLOSE'});
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Typography color="textPrimary" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Startsidan
          </Typography>
        </Breadcrumbs>
      </div>
      <div className={classes.root}>
        <Typography variant="h5" component="h2" className={classes.title}>Mina ärenden</Typography>
      </div>
      <MessageBar {...state.message} onClose={closeMessageBar} />
      <Loading loading={state.loading} hide={state.loadingError}>
        <CommentsList
          comments={state.comments}
        />
      </Loading>
    </Fragment>
  );

}

export default MyCases;
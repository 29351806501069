import React, { useEffect, Fragment, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HomeIcon from '@material-ui/icons/Home';
import PdfIcon from '@material-ui/icons/PictureAsPdf';

import { useAuthenticatedContext } from '../../hooks/AuthenticatedContext';
import { Comment, SaveComment } from './types';
import { Authenticated } from '../general/types';
import CaseReducer, { initialState } from './reducers';
import MessageBar from '../general/messageBar/MessageBar';
import ViewComment from './parts/viewComment/ViewComment';
import EditComment from './parts/editComment/EditComment';

import loadComments from './actions/loadComments';
import saveComment from './actions/saveComment';
import Loading from '../general/loading/Loading';
import Saving from '../general/saving/Saving';
import RenderIf from 'components/general/renderIf/RenderIf';
import { useGlobalMessage } from '../../hooks/GlobalMessage';
import ZoomableImage from 'components/general/ZoomableImage';

export interface CaseProps {
  caseno: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1),
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
    link: {
      color: theme.palette.text.primary,
      display: 'flex',
      textDecoration: 'none',
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    root: {
      display: 'flex',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    pdf: {
      display: 'flex',
      '& > *': {
        margin: 'auto 0',
      },
      '& > svg': {
        marginLeft: theme.spacing(1),
      },
    },
    images: {
      height: '70px',
      marginBottom: '1rem',
    },
  })
);

const Case = (props: CaseProps) => {
  const { caseno } = props;
  const classes = useStyles();
  const [state, dispatch] = useReducer(CaseReducer, initialState);
  const { setAuthenticated } = useAuthenticatedContext();
  const { setGlobalMessage } = useGlobalMessage();
  const history = useHistory();
  const hashUnid = window.location.hash
    ? window.location.hash.substring(1)
    : '';

  useEffect(() => {
    loadComments(caseno, dispatch).then(() => {
      if (hashUnid) {
        window.location.href = `#${hashUnid}`;
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state.loadingError401) {
      setAuthenticated({} as Authenticated);
    }
    // eslint-disable-next-line
  }, [state.loadingError401]);

  useEffect(() => {
    if (state.message && !state.loadingError) {
      setGlobalMessage(state.message);
    }
    // eslint-disable-next-line
  }, [state.message, state.loadingError]);

  const closeMessageBar = () => {
    dispatch({ type: 'ALERT_CLOSE' });
  };

  const getYttrandeComments = (): Comment[] => {
    if (state.comments && state.comments.length > 0) {
      const yComments: Comment[] = state.comments.filter(
        (comment) => comment.type === 'YttrandeComment'
      );
      return yComments;
    }
    return [];
  };

  const getMedComments = (): Comment[] => {
    if (state.comments && state.comments.length > 0) {
      const mComments: Comment[] = state.comments.filter(
        (comment) => comment.type === 'MedComment' && !comment.myComment
      );
      return mComments;
    }
    return [];
  };

  const getEditComment = (): Comment => {
    if (state.comments && state.comments.length > 0) {
      const editComments: Comment[] = state.comments.filter(
        (comment) => comment.myComment
      );
      if (editComments[0]) {
        return editComments[0];
      }
    }
    return {
      caseno: props.caseno,
    } as Comment;
  };

  const isCaseOnly = (): boolean => {
    return !state.comments || state.comments.length === 0;
  };

  const save = async (comment: SaveComment) => {
    return await saveComment(comment, dispatch);
  };

  const close = () => {
    history.push('/');
  };

  const saveAndClose = async (comment: SaveComment) => {
    if (await save(comment)) {
      close();
    }
  };

  const editComment = getEditComment();

  return (
    <Fragment>
      <div className={classes.container}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            {'Startsidan'}
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            {isCaseOnly()
              ? 'Skapa kommentar'
              : 'Kommentar av medicinsk sakkunnig'}
          </Typography>
        </Breadcrumbs>
      </div>
      <div className={classes.root}>
        <Typography variant="h5" component="h2" className={classes.title}>
          Ärendenummer: {caseno}
        </Typography>
        <RenderIf doRender={state.pdf ? true : false}>
          <div className={classes.pdf}>
            <a href={state.pdf} target={'_blank'} rel={'noopener noreferrer'}>
              Visa ärende PM
            </a>{' '}
            <PdfIcon />
          </div>
        </RenderIf>
      </div>
      <RenderIf doRender={(state?.images || []).length > 0}>
        <div>
          <Typography variant="h6" component="h3">
            Bifogade bilder
          </Typography>

          <div className={classes.images}>
            {(state.images || []).map((image) => (
              <ZoomableImage key={image.name} image={image} caseno={caseno} />
            ))}
          </div>
        </div>
      </RenderIf>

      <RenderIf doRender={state.loadingError}>
        <MessageBar {...state.message} onClose={closeMessageBar} />
      </RenderIf>

      <Saving saving={state.saving} />
      <Loading loading={state.loading} hide={state.loadingError}>
        <Fragment>
          {getYttrandeComments().map((comment, idx) => (
            <ViewComment key={idx} comment={comment} expanded />
          ))}
          <EditComment
            comment={editComment}
            onSave={save}
            onClose={close}
            onSaveAndClose={saveAndClose}
            scrollTo={editComment && editComment.unid === hashUnid}
          />
          {getMedComments().map((comment, idx) => (
            <ViewComment
              key={idx}
              comment={comment}
              expanded={comment.unid === hashUnid}
            />
          ))}
        </Fragment>
      </Loading>
    </Fragment>
  );
};

export default Case;

import { MessageBarProps } from '../general/messageBar/MessageBar';
export interface ListComment {
  unid: string;
  type: string;
  caseno: string;
  responsible: string;
  meetingDate: string;
  created: Date;
  updated: Date;
  unread?: boolean;
  chairDecision: boolean;
  postpone: boolean;
  myComment: boolean;
  initials: string;
}

export interface CaseNo {
  caseno: string;
  unread: number;
  comments: ListComment[];
}

export interface MeetingDate {
  meetingDate: string;
  unread: number;
  caseNoComments: CaseNo[];
}

export interface CommentCollection {
  pads?: MeetingDate[];
  lads?: MeetingDate[];
}

export interface MyCasesState {
  loading: boolean;
  loadingError: boolean;
  loadingError401: boolean;
  comments: CommentCollection;
  message?: MessageBarProps;
}

export interface MyCasesAction {
  type: LoadCommentsActionTypes | string;
  payload?: any;
}

export enum LoadCommentsActionTypes {
  START = 'LOAD_COMMENTS_START',
  DONE = 'LOAD_COMMENTS_DONE',
  ERROR = 'LOAD_COMMENTS_ERROR'
}
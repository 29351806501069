import { MyCasesState, MyCasesAction, LoadCommentsActionTypes, CommentCollection } from '../types';

export const initialState: MyCasesState = {
  loading: true,
  loadingError: false,
  loadingError401: false,
  comments: {}
};

const MyCasesReducer = (state: MyCasesState, action: MyCasesAction) => {
  switch (action.type) {
    case LoadCommentsActionTypes.START:
      return {
        ...state,
        loading: true
      } as MyCasesState;
    case LoadCommentsActionTypes.DONE:
      return {
        ...state,
        loading: false,
        comments: action.payload as CommentCollection
      } as MyCasesState;
    case LoadCommentsActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        comments: initialState.comments,
        message: {
          status: 'error',
          message: 'Ett fel inträffade när kommentarerna skulle hämtas'
        }
      } as MyCasesState;
    case 'ALERT_CLOSE':
      return {
        ...state,
        message: undefined
      }
    case 'LOGOUT':
      return {
        ...state,
        loadingError401: true
      }
    default:
      return {
        ...state,
        message: {
          status: 'error',
          message: 'Ett fel har uppstått. Kontakta oss gärna med en beskrivning på hur felet uppkom.'
        }
      } as MyCasesState;
  }
}

export default MyCasesReducer;
export const colors = {
  psnBlue: '#83b3d3',
  psnBlueLight: 'rgba(131, 179, 211, 0.1)',
  psnGreen: '#599674',
  psnGreenLight: '#85b79b',
  psnBlueDark: '#528ab8',
  primaryColor: '#528ab8',
  backgroundColor: '#e8ebf1',
  buttonRed: '#bd0b0b',
  errorRed: '#bd0b0b',
  successGreen: '#2ba92b',
  successGreenDark: '#1b6c1b',
  successGreenLight: '#cdf2cd',
  logoColor: '#2b4f79',
  psnBlueContrast: '#fff',
  warning: '#f0dc82',
  warningDark: '#b49027',
  labelGrey: '#969696'
};

export const elementDimensions = {
  footerHeight: '140px',
  footerMargin: '35px',
  headerHeight: '80px',
  headerMargin: '45px',
  navigationBarHeight: '45px',
  loggedOutHeaderFooterHeight: '321px',
  loggedInHeaderFooterHeight: '306px'
};

export const breakpoints = {
  desktopSmall: '992px',
  desktopMedium: '1170px',
  desktopLarge: '1250px',
  desktopHuge: '1400px'
};

import React, { useState, useEffect, useRef } from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { ICaseImage } from 'components/case/types';
import OpenSeadragon from 'openseadragon';
import { v4 as uuid } from 'uuid';
import * as constants from '../../utils/constants';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Button = styled.button`
  all: unset;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const ClickableImage = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

const OpenSeadragonContainer = styled.div`
  background: white;
  width: 90vw;
  height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Container = styled(Paper)<{ height?: number; width?: number }>`
  margin-right: 0.5rem;
  display: inline-block;
  overflow: hidden;
  height: ${({ height }) => height || '70px'};
  width: ${({ width }) => width || '70px'};
`;

export interface IZoomableImageProps {
  image: ICaseImage;
  caseno: string;
  alt?: string;
  width?: number;
  height?: number;
}

const Placeholder = styled.div`
  text-align: center;
  cursor: progress;
  width: 100%;
  height: 100%;
  display: block;
  background: ${constants.colors.psnGreen}
  color: white;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export default function ZoomableImage({
  caseno,
  image,
  alt,
  width,
  height,
}: IZoomableImageProps) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const src = `/api/comments/${caseno}/images/${image.name}`;

  return (
    <Container elevation={4}>
      {image.dzi && !image.dziReady ? (
        <Placeholder>
          <HourglassEmptyIcon />
          <span>Under behandling</span>
        </Placeholder>
      ) : (
        <Button onClick={() => setOpen(true)} type="button">
          <ClickableImage
            style={{
              backgroundImage: `url('${src}${image.dzi ? '/preview' : ''}')`,
            }}
          />
        </Button>
      )}
      <Modal
        className={classes.backdrop}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div>
          <Viewer image={image} caseno={caseno} />
        </div>
      </Modal>
    </Container>
  );
}

export interface IViewerProps {
  image: ICaseImage;
  caseno: string;
}

function Viewer({ image, caseno }: IViewerProps) {
  const viewerElementId = useRef(uuid());
  let tileSources: OpenSeadragon.Options['tileSources'] = `/api/comments/${caseno}/images/dzi/${image.name}`;
  if (!image.dzi) {
    tileSources = {
      type: 'image',
      url: `/api/comments/${caseno}/images/${image.name}`,
      getTileHashKey: () => image.name,
    } as any;
  }

  useEffect(() => {
    OpenSeadragon({
      id: viewerElementId.current,
      prefixUrl: 'https://openseadragon.github.io/openseadragon/images/',
      tileSources: tileSources,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <OpenSeadragonContainer id={viewerElementId.current} />;
}

import { Dispatch } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import expect from '@edgeguideab/expect';
import { LoginAction } from './loginReducer';
import { User } from '../useradmin/types';

export interface LoginResponse {
  success: boolean;
  message: string;
}

interface BankIDCheckResponse {
  msg?: string;
  status?: string;
  progress?: string;
  user?: any;
  csrfToken?: string;
}

const validate = (personalIdentityNumber: string) => {
  return expect(
    { personalIdentityNumber: 'identityNumber' },
    { personalIdentityNumber }
  ).wereMet();
};

const startLogin = async (
  personalIdentityNumber: string
): Promise<LoginResponse> => {
  try {
    const response: AxiosResponse = await axios.post(
      `/api/sessions/login`,
      { personalIdentityNumber },
      { withCredentials: true }
    );
    return {
      success: true,
      message: response.data.msg,
    } as LoginResponse;
  } catch (e) {
    if (!(e instanceof AxiosError)) {
      return {
        success: false,
        message: 'Ett oväntat fel inträffade',
      } as LoginResponse;
    }
    if (!e.response || e.response.status === 502) {
      return {
        success: false,
        message: 'BankID tjänsten svarar inte',
      } as LoginResponse;
    }
    return {
      success: false,
      message: e.response.data.msg,
    } as LoginResponse;
  }
};

const checkLogin = async (): Promise<BankIDCheckResponse> => {
  try {
    const response: AxiosResponse<BankIDCheckResponse> = await axios.get(
      '/api/sessions/check',
      { withCredentials: true }
    );
    return response.data as BankIDCheckResponse;
  } catch (e) {
    if (!(e instanceof AxiosError)) {
      return {};
    }
    return e?.response?.data as BankIDCheckResponse;
  }
};

let pollingInterval: NodeJS.Timeout;
const pollLoginStatus = async (loginDispatcher: Dispatch<LoginAction>) => {
  pollingInterval = setInterval(async () => {
    const result: BankIDCheckResponse = await checkLogin();

    if (result.progress === 'PENDING') {
      loginDispatcher({ type: 'PENDING', payload: result.msg });
    } else {
      clearInterval(pollingInterval);
      if (result.progress === 'AUTHENTICATED') {
        sessionStorage.setItem(
          'csrfToken',
          result.csrfToken ? result.csrfToken : ''
        );
        axios.defaults.headers.common = {
          'X-CSRFToken': result.csrfToken || '',
        };
        loginDispatcher({ type: 'DONE', payload: result.user });
      } else {
        loginDispatcher({ type: 'ERROR', payload: result.msg });
      }
    }
  }, 1000);
};

const cancelPollLoginStatus = () => {
  clearInterval(pollingInterval);
};

const ensureUserSession = async (): Promise<User> => {
  try {
    const response: AxiosResponse<User> = await axios.get(
      '/api/sessions/validate',
      { withCredentials: true }
    );
    return response.data as User;
  } catch (e) {
    return Promise.reject(e);
  }
};

export {
  validate,
  startLogin,
  pollLoginStatus,
  cancelPollLoginStatus,
  ensureUserSession,
};

import { User } from "components/useradmin/types";

export enum Progress {
  AUTHENTICATED = 'AUTHENTICATED',
  DENIED = 'DENIED',
  PENDING = 'PENDING'
}

export enum PendingHintCode {
  NoClient = 'noClient',
  UserSign  = 'userSign',
  OutstandingTransaction  = 'outstandingTransaction',
  Started = 'started'
}

export enum FailedHintCode {
  Cancelled = 'cancelled',
  CertificateErr = 'certificateErr',
  ExpiredTransaction = 'expiredTransaction',
  StartFailed = 'startFailed',
  UserCancel = 'userCancel',
  NetworkError = 'networkError'
}

export interface LoginResponse {
  success: boolean;
  message: string;
}

export interface BankIDCheckResponse {
  msg?: string;
  progress?: string;
  user?: any;
  csrfToken?: string;
  qrCode?: string;
  hintCode?: PendingHintCode | FailedHintCode;
}

export enum LoginStatus {
  EMPTY,
  VALIDATION_ERROR,
  VALIDATION_PASSED,
  LOGIN_START,
  LOGIN_PENDING,
  LOGIN_ERROR,
  LOGIN_SUCCESS
}

export enum LoginType {
  EMPTY,
  OTHER_UNIT,
  SAME_UNIT
}

export interface LoginState {
  inProgress: boolean;
  status: LoginStatus;
  message: string;
  loginType: LoginType;
  user?: User;
  autoStartToken?: string;
  qrCode?: string;
  hintCode?: PendingHintCode | FailedHintCode;
}

export enum LoginActionType {
  LOGIN_START,
  LOGIN_PENDING,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  CANCEL,
  ABORT
}

export interface LoginAction {
  type: LoginActionType,
  payload?: LoginState
}

export const initialState: LoginState = {
  inProgress: false,
  status: LoginStatus.EMPTY,
  message: '',
  loginType: LoginType.EMPTY
}
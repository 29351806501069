import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      flexShrink: 0,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      flexDirection: 'row',
      display: 'flex'
    },
    grid: {
      flexBasis: '33.33%', 
      color: theme.palette.getContrastText(theme.palette.primary.main),
      padding: theme.spacing(2),
      textAlign: 'center',
      '& > div': {
        display: 'inline-block',
        textAlign: 'left',
      },
      '@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)' : {
        padding: theme.spacing(1)
      }
    },
    linkContainer: {
      display: 'flex',
      alignItems: 'center',
      '& a:last-child': {
        borderLeft: '2px solid ' + theme.palette.getContrastText(theme.palette.primary.main),
        paddingLeft: theme.spacing(1),
        marginLeft: theme.spacing(1)
      }
    },
    link: {
      color: theme.palette.getContrastText(theme.palette.primary.main)
    }
  })
);

export const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <div className={classes.grid}>
        <div>
          <Typography variant={'h6'}>Kontakt</Typography>
          <Typography>PSN 08-522 787 44</Typography>
          <Typography>LMSN 08-522 787 48</Typography>
          <Typography>info@patientskadenamnden.se</Typography>
          <Typography>info@lakemedelsskadenamnden.se</Typography>
        </div>
      </div>
      <div className={classes.grid}>
        <div>
          <Typography variant={'h6'}>Adress</Typography>
          <Typography>Patientskadenämnden / Läkemedelsskadenämnden</Typography>
          <Typography>Box 24127</Typography>
          <Typography>104 51 Stockholm</Typography>
        </div>
      </div>
      <div className={`${classes.grid}`}>
        <div>
          <Typography variant={'h6'}>Länkar</Typography>
          <Typography>
            <a href={'https://www.patientskadenamnden.se'} target={'_blank'} rel={'noopener noreferrer'} className={classes.link}>www.patientskadenamnden.se</a>
          </Typography>
          <Typography>
            <a href={'https://www.lakemedelsskadenamnden.se'} target={'_blank'} rel={'noopener noreferrer'} className={classes.link}>www.lakemedelsskadenamnden.se</a>
          </Typography>
          <Typography>
            <a href={'https://www.patientskadenamnden.se/juridik/referat/referat/'} target={'_blank'} rel={'noopener noreferrer'} className={classes.link}>Patientskadenämndens referatsamling</a>
          </Typography>
          <Typography>
            <a href={'http://yttrande.lakemedelsskadenamnden.se/'} target={'_blank'} rel={'noopener noreferrer'} className={classes.link}>Läkemedelsskadenämndens referatsamling</a>
          </Typography>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ConfirmDialogProps {
  show: boolean;
  title: string;
  content: string;
  acceptTitle: string;
  cancelTitle: string;
  onAccept: any;
  onCancel: any;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const {show, title, content, acceptTitle, cancelTitle, onAccept, onCancel} = props;
  return (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">{cancelTitle}</Button>
        <Button onClick={onAccept} color="primary" autoFocus>{acceptTitle}</Button>
      </DialogActions>
    </Dialog>
  );
}
import { UpdateUserActionTypes, UserDialogProps, UserAdminState, UserAdminAction } from '../types';
import { MessageBarProps } from '../../general/messageBar/MessageBar';

const updateUserReducer = (state: UserAdminState, action: UserAdminAction) => {
  switch (action.type) {
    case UpdateUserActionTypes.UPDATE_USER_START:
      return {
        ...state,
        dialog: {
          show: true,
          user: action.payload
        } as UserDialogProps
      }
    case UpdateUserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        dialog: {
          show: false,
          user: undefined
        } as UserDialogProps,
        message: {
          status: 'success',
          message: 'Användaren har uppdaterats'
        } as MessageBarProps
      }
    case UpdateUserActionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        dialog: {
          show: false,
          user: undefined
        } as UserDialogProps,
        message: {
          status: 'error',
          message: action.payload
        } as MessageBarProps
      }
  }
}

export default updateUserReducer;
import { CaseState, CaseAction } from '../types';
import loadCommentsReducer from './loadCommentsReducer';
import saveCommentReducer from './saveCommentReducer';

export const initialState: CaseState = {
  loading: true,
  loadingError: false,
  loadingError401: false,
  saving: false,
  comments: []
};

const reducer = (state: CaseState, action: CaseAction) => {

  const newState = loadCommentsReducer(state, action) || saveCommentReducer(state, action);

  if (newState) return newState;

  switch (action.type) {
    case 'LOGOUT':
      return {
        ...state,
        loadingError401: true
      };
    case 'ALERT_CLOSE':
      return {
        ...state,
        message: undefined
      };
    default:
      return {
        ...state,
        message: {
          status: 'error',
          message: 'Ett fel har uppstått. Kontakta oss gärna med en beskrivning på hur felet uppkom.'
        }
      } as CaseState;
  }
}

export default reducer;
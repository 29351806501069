import { initialState, LoginAction, LoginActionType, LoginState, LoginStatus } from './types';

export const LoginReducer = (state: LoginState, action: LoginAction) => {
  switch (action.type) {
    case (LoginActionType.LOGIN_START):
      return {
        ...initialState,
        ...action.payload,
        status: LoginStatus.LOGIN_START,
        inProgress: true
      };
    case (LoginActionType.LOGIN_PENDING):
      return {
        ...state,
        ...action.payload,
        status: LoginStatus.LOGIN_PENDING,
        inProgress: true
      };
    case (LoginActionType.LOGIN_SUCCESS):
      return {
        ...state,
        ...action.payload,
        status: LoginStatus.LOGIN_SUCCESS,
        inProgress: false
      };
    case (LoginActionType.LOGIN_ERROR):
      return {
        ...state,
        ...action.payload,
        status: LoginStatus.LOGIN_ERROR,
        inProgress: false
      };
    case (LoginActionType.CANCEL):
      return {
        ...initialState
      };
    default:
      return {
        ...state
      } as LoginState;
  }
};
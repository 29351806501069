import React, { Fragment } from 'react';

interface RenderIfProps {
  doRender: boolean;
  children: any;
}

const RenderIf = (props: RenderIfProps) => {
  const { doRender, children } = props;
  if (doRender) {
    return (<Fragment>{children}</Fragment>);
  }
  return (null);
};

export default RenderIf;


import React from 'react';
import {Button, Typography } from '@material-ui/core';
import { QRCodeCanvas } from 'qrcode.react';
import { styles } from '../styles';
import { LoginAction, LoginType } from '../types';
import { cancelLogin } from '../actions/cancelLogin';
import { changeLoginType } from '../actions/changeLoginType';

export interface ILoginQRCodeProps {
  dispatch: React.Dispatch<LoginAction>;
  qrCode: string;
}

export const LoginQRCode = (props: ILoginQRCodeProps)  => {
  const classes = styles();
  const { dispatch, qrCode } = props;

  return (
    <>
      <Typography variant='h4' component='h2' className={classes.label}>Logga in med BankID</Typography>
      <QRCodeCanvas
        className={classes.qr}
        value={qrCode}
        size={200}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'L'}
        includeMargin={false}/>
      <ol className={classes.text}>
        <li>Starta BankID appen.</li>
        <li>Tryck på skanna QR-kod knappen.</li>
        <li>Skanna ovanstående QR kod.</li>
      </ol>
      <div className={classes.buttonColumn}>
        < Button
          type='submit'
          variant='outlined'
          color='primary'
          onClick={() => changeLoginType(LoginType.SAME_UNIT, dispatch)}
        >BankID på samma  enhet</Button>
        <Button
          type='submit'
          variant='outlined'
          color='primary'
          onClick={() => cancelLogin(dispatch)}
        >Avbryt</Button>
      </div>
    </>
  );
};
import axios, { AxiosResponse } from 'axios';
import { User } from '../types';

export interface DominoUserIDs {
  unid: string;
  id: string;
}

export interface DominoUser {
  name: string;
  email?: string;
  pads?: DominoUserIDs;
  lads?: DominoUserIDs; 
}

const getDominoUsers = async (userList?: User[]): Promise<DominoUser[]> => {
  try {
    const response: AxiosResponse = await axios.get('/api/users/domino');
    const dominoUsers = response.data as DominoUser[];
    if (userList) {
      return dominoUsers.filter(du => {
        for (const user of userList) {
          if (du.pads && du.pads.id === user.id) {
            return false;
          }
          if (du.lads && du.lads.id === user.id) {
            return false;
          }
        }
        return true;
      });
    }
    return dominoUsers;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
}

export default getDominoUsers;
import React from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface ButtonProps {
  onSave: any;
  onClose: any;
  onSaveAndClose: any;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  buttonRow: {
    display: 'flex',
    '& button': {
      marginRight: theme.spacing(1)
    },
    '& button:last-child': {
      marginRight: '0'
    }
  }
}));

const Buttons = (props: ButtonProps) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonRow}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={props.onSave}
      >
        {'Spara'}
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={props.onSaveAndClose}
      >
        {'Spara & Stäng'}
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CancelIcon />}
        onClick={props.onClose}
      >
        {'Avbryt'}
      </Button>
    </div>
  );
};

export default Buttons;
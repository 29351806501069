import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import {
  LoadCommentsActionTypes,
  CaseAction,
  Comment,
  CommentsWrapper,
} from '../types';
import createPurify from 'dompurify';

const loadComments = async (caseno: string, dispatch: Dispatch<CaseAction>) => {
  try {
    dispatch({ type: LoadCommentsActionTypes.START });
    const response: AxiosResponse = await axios.get(`/api/comments/${caseno}`);
    dispatch({
      type: LoadCommentsActionTypes.DONE,
      payload: {
        caseno: response.data.caseno,
        comments: sortByCreated(purifyComments(response.data.comments)),
        pdf: getPdfLink(
          response.data.caseno,
          response.data.unid,
          response.data.pdf
        ),
        unid: response.data.unid,
        source: response.data.system,
        images: response.data.images,
      } as CommentsWrapper,
    });
  } catch (error) {
    if ((error as any).response && (error as any).response.status === 401) {
      dispatch({ type: 'LOGOUT' });
    }
    dispatch({
      type: LoadCommentsActionTypes.ERROR,
      payload: (error as any).response.data,
    });
  }
};

const purify = createPurify();
const purifyComments = (comments: Comment[]): Comment[] => {
  return comments.map((comment) => {
    return {
      ...comment,
      created: new Date(comment.created),
      updated: new Date(comment.updated),
      comment: purify.sanitize(comment.comment),
    } as Comment;
  });
};

const getPdfLink = (caseno: string, unid: string, pdf: string) => {
  if (pdf) {
    const pdfArray = pdf.split(';').sort((a, b) => -a.localeCompare(b));
    return `/api/comments/${caseno}/${unid}/${pdfArray[0]}`;
  }
  return undefined;
};

const sortByCreated = (comments: Comment[]) => {
  return comments.sort((a, b) =>
    a.created.getTime() > b.created.getTime() ? -1 : 1
  );
};

export default loadComments;

import React, { useEffect, useRef } from 'react';
import { Comment } from '../../types';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import compareDates from 'utils/compareDates';

interface ViewCommentProps {
  comment: Comment | undefined;
  expanded?: boolean;
}

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    marginBottom: '16px',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: '0 0 16px 0'
    },
    '&$expanded:last-child': {
      margin: '0 0 16px 0'
    }
  },
  expanded: {}
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    flexDirection: 'column',
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(AccordionDetails);


const useStyles = makeStyles((theme: Theme) => createStyles({
  label: {
    flexGrow: 1
  }
}));

const ViewComment = (props: ViewCommentProps) => {
  const { comment } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const commentRef = useRef<HTMLDivElement>(null);

  useEffect(()=> {
    setExpanded(props.expanded ? props.expanded : false);
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (comment && comment.type === 'MedComment' && props.expanded) {
      if (commentRef && commentRef.current) {
        const node = commentRef.current;
        setTimeout(()=> {
          node.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }, 1000);
      }
    }
  // eslint-disable-next-line
  }, [commentRef]);

  if (!comment) return (null);

  let label = '';
  switch (comment.type) {
    case 'YttrandeComment':
      label = `Kommentar av föredragande jurist ${comment.initials}`; break;
    case 'MedComment':
      label = `Kommentar av medicinskt sakkunnig ${comment.initials}`; break;
    default:
      return (null);
  }

  let date = '';
  if (compareDates(comment.updated, comment.created)) {
    date = `Uppdaterad ${comment.updated.toLocaleDateString()}`;
  } else {
    date = `Skapad ${comment.created.toLocaleDateString()}`;
  }

  const handleChange = () => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <ExpansionPanel
      id={comment.unid}
      square
      expanded={expanded}
      onChange={handleChange()}
    >
      <ExpansionPanelSummary
        ref={commentRef}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography variant="body1" component="h3" className={classes.label}>{label}</Typography>
        <Typography variant="body2">{date}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>     
        <div dangerouslySetInnerHTML={{__html: comment.comment}} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ViewComment;
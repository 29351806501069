import React, { Fragment } from 'react';
import { makeStyles, createStyles, Theme, Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1)
    },
    alert: {
      marginBottom: theme.spacing(2)
    },
    link: {
      color: theme.palette.text.primary,
      display: 'flex',
      textDecoration: 'none'
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: '900px'
    },
    title: {
      marginTop: theme.spacing(2)
    },
    paragraph: {
      marginTop: theme.spacing(2)
    }
  }));

const About = () => {
const classes = useStyles();

  return (
    <Fragment>
    <div className={classes.container}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/" className={classes.link}>
          <HomeIcon className={classes.icon} />
          {'Startsidan'}
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          <PeopleIcon className={classes.icon} />
          {'Om portalen'}
        </Typography>
      </Breadcrumbs>
    </div>
    <div className={classes.root}>
      <Typography variant="h5" component="h2" className={classes.title}>Om portalen</Typography>
      <Typography className={classes.paragraph}>
        Välkommen till Patientskadenämndens och Läkemedelsskadenämndens portal för medicinskt sakkunniga.
      </Typography>
      <Typography className={classes.paragraph}>
        Portalen kommunicerar direkt med Päds respektive Läds. Kommentarer som föredraganden skriver i dessa system visas i portalen och de kommentarer som du skriver i portalen visas i respektive system för föredraganden och ordföranden.
      </Typography>
      <Typography className={classes.paragraph}>
        I det fall ärendet är ett så kallat ordförandebeslut, visas en pdf högst upp till höger på sidan med kommentarer. I övriga fall, vanliga nämndärenden, hittar du ärenden i TeamEngine cirka 3 veckor innan sammanträdet.
      </Typography>
      <Typography className={classes.paragraph}>
        Har du förbättringsförslag, eller allmänna idéer, kontakta Andreas Nordström på kansliet genom <a href='mailto:andreas.nordstrom@pff.se'>andreas.nordstrom@pff.se</a>.
      </Typography>
      <Typography className={classes.paragraph}>
      Vid mer akuta problem, kontakta kansliet genom <a href='mailto:psn_lmsn@pff.se'>psn_lmsn@pff.se</a> alt Annika Neuman genom <a href='mailto:annika.neuman@sfadm.se'>annika.neuman@sfadm.se</a>.
      </Typography>
    </div>
    </Fragment>
  );

};

export default About;
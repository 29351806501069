import {
  CaseState,
  CaseAction,
  LoadCommentsActionTypes,
  Comment,
} from '../types';
import { initialState } from '../../mycases/reducers/MyCasesReducer';

const loadCommentsReducer = (state: CaseState, action: CaseAction) => {
  switch (action.type) {
    case LoadCommentsActionTypes.START:
      return {
        ...state,
        loading: true,
      } as CaseState;
    case LoadCommentsActionTypes.DONE:
      return {
        ...state,
        loading: false,
        comments: action.payload.comments as Comment[],
        pdf: action.payload.pdf,
        images: action.payload.images,
        unid: action.payload.unid,
        caseno: action.payload.caseno,
      } as CaseState;
    case LoadCommentsActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingError: true,
        comments: initialState.comments,
        message: {
          status: 'error',
          message: 'Ett fel inträffade när kommentarerna skulle hämtas',
        },
      } as CaseState;
  }
};

export default loadCommentsReducer;

import { UserAdminState, UserAdminAction, UserDialogProps } from '../types';
import loadUsersReducer from './loadUsersReducer';
import createUserReducer from './createUserReducer';
import updateUserReducer from './updateUserReducer';
import deleteUserReducer from './deleteUserReducer';
import { MessageBarProps } from '../../general/messageBar/MessageBar';

export const initialState: UserAdminState = {
  loading: true,
  userList: [],
  dialog: {
    show: false
  } as UserDialogProps
};

const reducer = (state: UserAdminState, action: UserAdminAction) => {

  const newState = loadUsersReducer(state, action) || 
    createUserReducer(state, action) ||
    updateUserReducer(state, action) ||
    deleteUserReducer(state, action);

  if (newState) return newState;

  switch (action.type) {
    case 'USER_DIALOG_CLOSE':
      return {
        ...state,
        dialog: {
          ...state.dialog,
          show: false,
          user: undefined
        } as UserDialogProps
      } 
    case 'ALERT_CLOSE':
      return {
        ...state,
        message: undefined
      }
    default:
      return {
        ...state,
        message: {
          status: 'error',
          message: 'Ett fel har uppstått. Kontakta oss gärna med en beskrivning på hur felet uppkom.'
        } as MessageBarProps
      }
  }
}

export default reducer;
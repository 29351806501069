import { makeStyles } from "@material-ui/core";

export const styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 'auto',
    maxWidth: 500
  },
  label: {
    marginBottom: theme.spacing(2)
  },
  qr: {
    margin: theme.spacing(2)
  },
  text: {
    fontSize: '1rem'
  },
  logo: {
    margin: theme.spacing(0, 'auto', 3, 'auto'),
    width: '120px'
  },
  buttonColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    gap: theme.spacing(2),
    margin: theme.spacing(3, 'auto', 2)
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'start',
    gap: theme.spacing(2),
    margin: theme.spacing(3, 'auto', 2)
  },
  help: {
    margin: theme.spacing(3, 'auto', 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  }
}));
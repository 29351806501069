import { cancelPollLoginStatus } from './pollLoginStatus';
import axios from 'axios';
import { Dispatch } from 'react';
import { LoginAction, LoginActionType } from "../types";

export const cancelLogin = async (dispatch: Dispatch<LoginAction>): Promise<void> => {
  cancelPollLoginStatus();
  try {
    await axios.post(`/api/sessions/cancel`, {}, { withCredentials: true });
    dispatch({
      type: LoginActionType.CANCEL
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: LoginActionType.CANCEL
    });
  }
};
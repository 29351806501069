import axios, { AxiosError, AxiosResponse } from "axios";
import { BankIDCheckResponse, FailedHintCode, Progress } from "../types";

export const checkLogin = async (): Promise<BankIDCheckResponse> => {
  try {
    const response: AxiosResponse<BankIDCheckResponse> = await axios.get(
      '/api/sessions/check',
      { withCredentials: true }
    );
    return response.data as BankIDCheckResponse;
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e?.response?.data?.progress) {
        return e.response.data as BankIDCheckResponse;
      }
    }
    console.error(e);
    return {
      msg: 'Ett kommunikationsfel inträffade mot inloggningstjänsten.',
      hintCode:  FailedHintCode.NetworkError,
      progress: Progress.DENIED
    };
  }
};
import { DeleteUserActionTypes, UserAdminState, UserAdminAction, DeleteUserDialogState, User } from '../types';
import { MessageBarProps } from '../../general/messageBar/MessageBar';

const deleteUserReducer = (state: UserAdminState, action: UserAdminAction) => {
  switch (action.type) {
    case DeleteUserActionTypes.DELETE_USER_START:
      const user: User = action.payload;
      return {
        ...state,
        deleteDialog: {
          show: true,
          user: action.payload,
          message: `Vill du ta bort ${`${user.firstName} ${user.lastName}`.trim()}?`
        } as DeleteUserDialogState
      }
    case DeleteUserActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteDialog: undefined,
        message: {
          status: 'success',
          message: 'Användaren har tagits bort'
        } as MessageBarProps
      }
    case DeleteUserActionTypes.DELETE_USER_ERROR:
      return {
        ...state,
        deleteDialog: undefined,
        message: {
          status: 'error',
          message: 'Ett fel inträffade när användaren skulle tas bort'
        } as MessageBarProps
      }
    case DeleteUserActionTypes.DELETE_USER_CANCEL:
        return {
          ...state,
          deleteDialog: undefined
        }
  }
}

export default deleteUserReducer;
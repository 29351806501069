import { CaseState, CaseAction, Comment, SaveCommentActionTypes } from '../types';

const saveCommentReducer = (state: CaseState, action: CaseAction) => {
  switch (action.type) {
    case SaveCommentActionTypes.START:
      return {
        ...state,
        saving: true
      } as CaseState;
    case SaveCommentActionTypes.DONE:
      return {
        ...state,
        saving: false,
        comments: action.payload as Comment[],
        message: {
          status: 'success',
          message: 'Kommentaren har sparats'
        }
      } as CaseState;
    case SaveCommentActionTypes.ERROR:
      return {
        ...state,
        saving: false,
        message: {
          status: 'error',
          message: 'Ett fel inträffade när kommentaren sparades'
        }
      } as CaseState;
  }
}

export default saveCommentReducer;
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';

interface LoadingProps {
  loading: boolean;
  hide: boolean;
  children: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  })
);

const Loading = (props: LoadingProps) => {
  const classes = useStyles();
  const [ showLoading, setShowLoading ] = React.useState<boolean>(false);
  const { loading, hide, children } = props;

  React.useEffect(()=>{
    if (loading) {
      const timeout = setTimeout(()=> {
        setShowLoading(true);
      }, 1000);
      return ()=> {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    } else {
      setShowLoading(false);
    }
  }, [loading]);

  if (!loading && !hide) {
    return (children);
  }

  if (showLoading) {
    return (
      <div className={classes.root}>
        <Typography variant={'button'} gutterBottom>{'Hämtar data'}</Typography>
        <CircularProgress />
      </div>
    );
  }
  return (null);
};

export default Loading;


import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Authenticated } from '../components/general/types';
import { ensureUserSession } from 'components/login/loginActions';
import { User } from '../components/useradmin/types';
import logoutAction from '../components/general/actions/logout';
import Loading from 'components/general/loading/Loading';
import axios from 'axios';

interface IAuthenticatedContext {
  authenticated: Authenticated;
  setAuthenticated: React.Dispatch<React.SetStateAction<Authenticated>>;
}

const defaultAuthenticated: Authenticated = {
  firstName: '',
  lastName: '',
  isAdmin: false,
  isAuthenticated: false,
};

const initialState: IAuthenticatedContext = {
  authenticated: defaultAuthenticated,
  setAuthenticated: (): void => {},
};

const AuthenticatedContext = createContext<IAuthenticatedContext>(initialState);

export const AuthenticatedContextProvider = (props: any) => {
  const [authenticated, setAuthenticated] = useState<Authenticated>(defaultAuthenticated);
  const [verifying, setVerifying] = useState<boolean>(true);

  const verifySession = async () => {
    try {
      const user: User = await ensureUserSession();
      setAuthenticated({
        firstName: user.firstName,
        lastName: user.lastName,
        isAdmin: user.role === 'ADMIN',
        isAuthenticated: true,
      } as Authenticated);
    } catch (error) {}
    setVerifying(false);
  };

  useEffect(() => {
    if (sessionStorage.getItem('csrfToken')) {
      axios.defaults.headers.common = {
        'X-CSRFToken': sessionStorage.getItem('csrfToken') || '',
      };
    }
    verifySession();
  }, []);

  const prevAuthenticatedRef = useRef(defaultAuthenticated);
  useEffect(() => {
    const prevAuth = prevAuthenticatedRef.current;
    if (prevAuth.isAuthenticated && !authenticated.isAuthenticated) {
      logoutAction();
    }
    prevAuthenticatedRef.current = authenticated;
  }, [authenticated]);

  return (
    <AuthenticatedContext.Provider
      value={{ authenticated, setAuthenticated } as IAuthenticatedContext}
    >
      <Loading loading={verifying} hide={false}>
        {props.children}
      </Loading>
    </AuthenticatedContext.Provider>
  );
};

export const useAuthenticatedContext = () => useContext(AuthenticatedContext);

import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { LoginAction, LoginActionType, LoginType } from '../types';
import { styles } from '../styles';
import { startLogin } from '../actions/startLogin';

export interface ILoginErrorProps {
  dispatch: React.Dispatch<LoginAction>;
  message: string;
  loginType: LoginType;
}

export const LoginError = (props: ILoginErrorProps)  => {
  const classes = styles();
  const { dispatch, message, loginType } = props;

  return (
    <>
      <Typography variant='h4' component='h2' className={classes.label}>Logga in med BankID</Typography>
      <p className={classes.text}>
        {message}
      </p>
      <div className={classes.buttonRow}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={() => startLogin(loginType, dispatch)}
        >Försök igen</Button>
        <Button
          type='submit'
          variant='outlined'
          color='primary'
          onClick={() => dispatch({type: LoginActionType.CANCEL})}
        >Avbryt</Button>
      </div>
    </>
  );
};


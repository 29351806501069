import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { User } from '../../types';
import { IconButton, Tooltip, Avatar } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';

interface UserTableRowProps {
  user: User;
  editUser: any;
  deleteUser: any;
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12
  }
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      padding: '10px 16px',
      verticalAlign: 'center'
    },
    nameContent: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginRight: theme.spacing(1)
      }
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: '0.75rem'
    }
  })
);

const UserTableRow = (props: UserTableRowProps) => {
  const classes = useStyles();
  const { user, editUser, deleteUser } = props;
  const systems: Array<string> = [];
  if (user.pads) systems.push('PADS');
  if (user.lads) systems.push('LADS');
  const name = [user.firstName, user.lastName].join(' ').trim();
  return (
    <TableRow hover onDoubleClick={()=>editUser(user)}>
      <TableCell className={classes.tableCell}>
        <div className={classes.nameContent}>
          <Avatar className={classes.avatar} alt={name}></Avatar>
          {name}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {user.email}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {systems.join(", ")}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <LightTooltip title="Redigera" arrow enterDelay={1000}>
          <IconButton onClick={()=>editUser(user)} size={'small'} aria-label="Redigera">
            <EditIcon />
          </IconButton>
        </LightTooltip>
        { user.role !== 'ADMIN' ? (
          <LightTooltip title="Ta bort" arrow enterDelay={1000}>
            <IconButton onClick={()=>deleteUser(user)} size={'small'} aria-label="Ta bort">
              <DeleteIcon />
            </IconButton>
          </LightTooltip>
        ) : null }
      </TableCell>
    </TableRow>
  );
};

export default UserTableRow;
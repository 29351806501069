import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { CaseNo, ListComment, MyCasesAction, LoadCommentsActionTypes, MeetingDate } from '../types';

const loadComments = async (dispatch: Dispatch<MyCasesAction>) => {
  try {
    dispatch({type: LoadCommentsActionTypes.START});
    const response: AxiosResponse = await axios.get('/api/comments');
    dispatch({type: LoadCommentsActionTypes.DONE, payload: {
      pads: sortByMeetingDate(group(response.data.pads)),
      lads: sortByMeetingDate(group(response.data.lads))
    }});
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({type: 'LOGOUT'});
    }
    dispatch({type: LoadCommentsActionTypes.ERROR, payload: error.response.data});
  }
}

const group = (comments: ListComment[]): MeetingDate[] => {
  const uniqueMeetingDates = comments.map(item => item.meetingDate).filter((value, index, self) => self.indexOf(value) === index);
  return uniqueMeetingDates.map(meetingDate => {
    const caseNoComments = getCaseNo(comments.filter(comment => comment.meetingDate === meetingDate), meetingDate);
    return {
      meetingDate: meetingDate,
      unread: caseNoComments.reduce((a, b) => a + b.unread, 0),
      caseNoComments: sortByCaseNo(caseNoComments)
    } as MeetingDate;
  });
};

const getCaseNo = (comments: ListComment[], meetingDate: string): CaseNo[] => {
  const uniqueCaseNo = comments.map(item => item.caseno).filter((value, index, self) => self.indexOf(value) === index);
  return uniqueCaseNo.map(caseno => {
    const filteredComments = comments.filter(comment=>comment.caseno === caseno);
    return {
      caseno: caseno,
      unread: filteredComments.reduce((a, b) => a + getUnread(b), 0),
      comments: sortByCreated(filteredComments.map(comment=>({
        ...comment,
        created: new Date(comment.created),
        updated: new Date(comment.updated)
      } as ListComment)))
    } as CaseNo;
  });
}

const getUnread = (comment: ListComment) => {
  return comment.unread ? 1 : 0;
}

const sortByMeetingDate = (meetingDates: MeetingDate[]) => 
  meetingDates.sort((a, b) => {
    return a.meetingDate.localeCompare(b.meetingDate);
  }
);

const sortByCaseNo = (comments: CaseNo[]) => 
  comments.sort((a, b) => {
    return a.caseno.localeCompare(b.caseno);
  }
);

const sortByCreated = (comments: ListComment[]) => 
  comments.sort((a, b) => {
    return a.created.getTime() > b.created.getTime() ? 1 : -1;
  }
);

export default loadComments;
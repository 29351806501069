import axios from 'axios';

const logout = async () => {
  try {
    await axios.post(`/api/sessions/logout`);
    sessionStorage.removeItem('csrfToken');
    axios.defaults.headers.common = {};
  } catch (error) {
    Promise.reject(error);
  }
}

export default logout;
import React, { createContext, useContext, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MessageBar from '../components/general/messageBar/MessageBar';
import { MessageProps } from '../components/general/types';
import Fade from '@material-ui/core/Fade';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(7)
  }
}));

interface IGlobalMessageState extends MessageProps {
  open: boolean;
}

interface IGlobalMessageContext {
  state: IGlobalMessageState;
  setGlobalMessage: (props: MessageProps) => any;
}

const defaultState: IGlobalMessageState = {
  message: '',
  open: false
};

const initialState: IGlobalMessageContext = {
  state: defaultState,
  setGlobalMessage: (): void => {}
};

const GlobalMessageContext = createContext<IGlobalMessageContext>(initialState);

export const GlobalMessageProvider = (props: any) => {
  const classes = useStyles();
  const [state, setState] = useState<IGlobalMessageState>(defaultState);
  
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const setGlobalMessage = (messageProps: MessageProps) => {
    setState({ ...messageProps, open: true });
  };

  return (
    <GlobalMessageContext.Provider value={{state, setGlobalMessage} as IGlobalMessageContext}>
      <Snackbar
        className={classes.root}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={5000}
        open={state.open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MessageBar message={state.message} status={state.status} />
      </Snackbar>
      {props.children}
    </GlobalMessageContext.Provider>
  );
}

export const useGlobalMessage = () => useContext(GlobalMessageContext);
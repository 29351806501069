import React, { useEffect, useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { UserDialogState, UserDialogProps, User } from '../../types';
import * as constants from 'utils/constants';
import getDominoUsers from '../../actions/getDominoUsers';
import { DominoUser } from '../../actions/getDominoUsers';
import validateUser from '../../actions/validateUser';

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    marginTop: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(2),
    backgroundColor: constants.colors.psnBlueDark,
    color: theme.palette.getContrastText(constants.colors.psnBlueDark)
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  option: {
    display: 'flex',
    width: '100%'
  },
  optionLabel: {
    flexGrow: 1
  }
}));

const TextInput = (props: any) => (
  <TextField
    {...props}
    fullWidth
    variant={'outlined'}
    className={useStyles().field}
    error={props.error ? true : false}
    helperText={props.error}
  />
);

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  pin: '',
  dominoUser: undefined
} as UserDialogState;

const UserDialog = (props: UserDialogProps) => {
  const classes = useStyles();
  const [{firstName, lastName, email, pin, dominoUser}, setState] = useState(initialState);
  const [selectDominoUser, setSelectDominoUser] = useState(false);
  const [dominoUsers, setDominoUsers] = useState([] as DominoUser[]);
  const [errors, setErrors] = useState({} as any);
  const {show, user, userList} = props;
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  };

  const handleDominoUserChange = (event: React.ChangeEvent<any>, dominoUser: DominoUser | null) => {
    if (dominoUser) {
      const idx = dominoUser.name.lastIndexOf(' ');
      const fName = idx > -1 ? dominoUser.name.substring(0, idx) : '';
      const lName = idx > -1 ? dominoUser.name.substring(idx + 1) : dominoUser.name;
      setState(prevState => ({
        ...prevState,
        firstName: fName,
        lastName: lName,
        email: dominoUser.email ? dominoUser.email : prevState.email,
        dominoUser: dominoUser
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        dominoUser: undefined
      }));
    }
  };

  const getSystemUNID = ( system: 'pads' | 'lads', dominoUser?: DominoUser) => {
    return dominoUser && dominoUser[system] ? dominoUser[system]!.unid : '';
  }

  const save = () => {
    const saveUser = {
      ...user,
      firstName: firstName,
      lastName: lastName,
      email: email,
      pin: pin,
      role: 'USER',
      pads: getSystemUNID('pads', dominoUser),
      lads: getSystemUNID('lads', dominoUser)
    } as User;
    const expectations = validateUser(saveUser);
    if (expectations.wereMet()) {
      props.onSave(saveUser);
    } else {
      setErrors(expectations.errors());
    }
  }

  const close = () => {
    props.onClose();
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setErrors(validateUser({
        firstName: firstName,
        lastName: lastName,
        email: email,
        pin: pin
      } as User).errors());
    }
  // eslint-disable-next-line
  }, [firstName, lastName, email, pin]);

  useEffect(() => {
    if (show) {
      setSelectDominoUser(!(user && user.id && (user.lads || user.pads)));
      if (user) {
        setState({
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          email: user.email || '',
          pin: user.pin || ''
        });
      }
    } else {
      setState(initialState);
      setErrors({});
      setSelectDominoUser(false);
    }
  }, [show, user]);

  useEffect(() => {
    if (selectDominoUser) {
      getDominoUsers(userList).then(dominoUsers => {
        setDominoUsers(dominoUsers);
      });
    }
  // eslint-disable-next-line
  }, [selectDominoUser]);

  return (
    <div>
      <Dialog open={show} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
          {user ? 'Redigera användare' : 'Lägg till ny användare'}
        </DialogTitle>
        <DialogContent>
          <form noValidate>
            { selectDominoUser ? (
              <Autocomplete
                id={'dominoUser'}
                options={dominoUsers}
                getOptionLabel={(option) => option.name}
                renderOption={(option) => {
                  const systems: Array<string> = [];
                  if (option.pads) systems.push('PADS');
                  if (option.lads) systems.push('LADS');
                  return (
                    <div className={classes.option}>
                      <div className={classes.optionLabel}>{option.name}</div>
                      <div>{systems.join(' & ')}</div>
                    </div>
                  );
                }}
                renderInput={(params:any) => <TextInput {...params} label={'Användare i Päds / Läds'} />}
                onChange={handleDominoUserChange}
              />
            ) : null }
            <TextInput id={'firstName'} label={'Förnamn'} value={firstName} onChange={handleChange} required error={errors.firstName}/>
            <TextInput id={'lastName'} label={'Efternamn'} value={lastName} onChange={handleChange} required error={errors.lastName}/>
            <TextInput id={'pin'} label={'Personnummer'} value={pin} onChange={handleChange} required error={errors.pin}/>
            <TextInput id={'email'} label={'Epost'} value={email} onChange={handleChange} error={errors.email}/>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Avbryt
          </Button>
          <Button onClick={save} color="primary">
            Spara
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UserDialog;
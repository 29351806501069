import React, { useEffect, useReducer } from 'react';
import { Button, Container, Link, Typography } from '@material-ui/core';
import logo from './logo-bankid.svg';
import { initialState, LoginStatus, LoginType, PendingHintCode } from './types';
import { LoginReducer } from './LoginReducer';
import { startLogin } from './actions/startLogin';
import { useAuthenticatedContext } from 'hooks/AuthenticatedContext';
import { Authenticated } from 'components/general/types';
import { styles } from './styles';
import { LoginError } from './parts/LoginError';
import { LoginQRCode } from './parts/LoginQRCode';
import { LoginSameUnit } from './parts/LoginSameUnit';
import { cancelLogin } from './actions/cancelLogin';
import { LoginEnterCode } from './parts/LoginEnterCode';

export const Login2 = () => {
  const classes = styles();
  const { setAuthenticated } = useAuthenticatedContext();
  const [ state, dispatch ] = useReducer(LoginReducer, initialState);

  const { hintCode, inProgress, message, qrCode, status, user, loginType, autoStartToken } = state;

  useEffect(() => {
    if (user) {
      setAuthenticated({
        isAuthenticated: true,
        firstName: user.firstName,
        lastName: user.lastName,
        isAdmin: user.role === 'ADMIN'
      } as Authenticated);
    }
  }, [user, setAuthenticated]);

  return (
    <Container className={classes.container}>
      {!inProgress && status === LoginStatus.EMPTY &&
        <>
          <img className={classes.logo} src={logo} alt="Bank ID" />
          <div className={classes.buttonColumn}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={() => startLogin(LoginType.OTHER_UNIT, dispatch)}
            >Logga in med BankID</Button>
            <Button
              type='submit'
              variant='outlined'
              color='primary'
              onClick={() => startLogin(LoginType.SAME_UNIT, dispatch)}
            >BankID på samma  enhet</Button>
          </div>
          <div className={classes.help}>
            <Link href="https://support.bankid.com/sv/bankid/mobilt-bankid" target="_blank" className={classes.text}>
              Så skaffar du mobilt BankID.
            </Link>
            <Typography className={classes.text}>
              <Link href="mailto:info@patientskadenamnden.se">Kontakta oss</Link> om du saknar användaruppgifter.
            </Typography>
          </div>
        </>
      }
       {inProgress && hintCode === PendingHintCode.OutstandingTransaction && loginType === LoginType.OTHER_UNIT &&
        <LoginQRCode dispatch={dispatch} qrCode={qrCode || ''}/>
      }
      {inProgress && autoStartToken && hintCode === PendingHintCode.OutstandingTransaction && loginType === LoginType.SAME_UNIT &&
        <LoginSameUnit dispatch={dispatch} autoStartToken={autoStartToken}/>
      }
      {inProgress && hintCode === PendingHintCode.UserSign &&
        <LoginEnterCode dispatch={dispatch}/>
      }
      {status === LoginStatus.LOGIN_ERROR &&
        <LoginError dispatch={dispatch} message={message} loginType={loginType}/>
      }
    </Container>

  );
};
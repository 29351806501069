import React from 'react';
import {Button, Typography } from '@material-ui/core';
import { styles } from '../styles';
import { LoginAction } from '../types';
import { cancelLogin } from '../actions/cancelLogin';

export interface ILoginEnterCodeProps {
  dispatch: React.Dispatch<LoginAction>;
}

export const LoginEnterCode = (props: ILoginEnterCodeProps)  => {
  const classes = styles();
  const { dispatch } = props;

  return (
    <>
      <Typography variant='h4' component='h2' className={classes.label}>Logga in med BankID</Typography>
      <p className={classes.text}>
        Skriv in din säkerhetskod i BankID-appen och välj Identifiera.
      </p>
      <div className={classes.buttonColumn}>
        <Button
          type='submit'
          variant='outlined'
          color='primary'
          onClick={() => cancelLogin(dispatch)}
        >Avbryt</Button>
      </div>
    </>
  );
};
import React from 'react';
import { ListComment } from '../../types';
import TreeItem from '@material-ui/lab/TreeItem';
import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import StarIcon from '@material-ui/icons/Star';
import compareDates from '../../../../utils/compareDates';

export interface ListCommentRowProps {
  comment: ListComment;
  openCase: (comment: ListComment) => any;
  isSingle?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.75rem',
    color: 'grey',
    marginRight: theme.spacing(1)
  },
  col1: {
    flex: '0 0 320px'
  },
  col2: {
  },
  col3: {
    marginLeft: theme.spacing(2)
  }
}));

const CommentRow = (props: ListCommentRowProps) => {
  const classes = useStyles();
  const { comment, openCase, isSingle } = props;

  if (isSingle && comment.type === 'Case') {
    return (
      <TreeItem
        nodeId={comment.unid}
        label={
          <div className={classes.row} onClick={() => openCase(comment)}>
            <CommentIcon className={classes.icon}/>
            <Typography className={classes.col1}>{`Skapa kommentar`}</Typography>
          </div>
        }
      />
    );
  }
  
  let label = '';
  switch (comment.type) {
    case 'YttrandeComment':
      label = 'Kommentar av föredragande jurist'; break;
    case 'MedComment':
      label = 'Kommentar av medicinskt sakkunnig'; break;
    default:
      return (null);
  }

  let updated = '';
  if (compareDates(comment.updated, comment.created)) {
    updated = `Uppdaterad ${comment.updated.toLocaleDateString()}`;
  }
  
  const unread = comment.type === 'YttrandeComment' && comment.unread ? <StarIcon color={'error'}/> : null;

  return (
    <TreeItem
      nodeId={comment.unid}
      endIcon={unread}
      label={
        <div className={classes.row} onClick={()=>openCase(comment)}>
          <CommentIcon className={classes.icon}/>
          <Typography className={classes.col1}>{`${label} ${comment.initials}`}</Typography>
          <Typography className={classes.col2}>{`Skapad ${comment.created.toLocaleDateString()}`}</Typography>
          <Typography className={classes.col3}>{updated}</Typography>
        </div>
      }
    />
  );
};

export default CommentRow;
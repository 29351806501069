import axios, { AxiosResponse } from 'axios';
import { User, UserAdminAction, CreateUserActionTypes } from '../types';
import { Dispatch } from 'react';
import loadUsers from './loadUsers';

const createUser = async (dispatch: Dispatch<UserAdminAction>, user: User): Promise<User> => {
  try {
    const response: AxiosResponse = await axios.post('/api/users', user);
    dispatch({type: CreateUserActionTypes.CREATE_USER_SUCCESS});
    loadUsers(dispatch);
    return response.data;
  } catch (error) {
    dispatch({type: CreateUserActionTypes.CREATE_USER_ERROR, payload: error.response.data});
    return {};
  }
}

export default createUser;
import React from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import UserTableRow from './UserTableRow';
import * as constants from 'utils/constants';
import { User } from '../../types';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  editColumn: {
    width: '96px'
  },
  systemsColumn: {
    width: '150px'
  }
});

const TableHeadCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: constants.colors.psnBlueDark,
      color: theme.palette.getContrastText(constants.colors.psnBlueDark)
    },
    body: {
      fontSize: 14
    }
  })
)(TableCell);

interface UserTableProps {
  userList: User[];
  editUser: any;
  deleteUser: any;
}

const UserTable = (props: UserTableProps) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              Namn
            </TableHeadCell>
            <TableHeadCell>
              Epost
            </TableHeadCell>
            <TableHeadCell className={classes.systemsColumn}>
              Päds / Läds
            </TableHeadCell>
            <TableHeadCell className={classes.editColumn} />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.userList!.map(user => (
            <UserTableRow
              key={user.id}
              user={user}
              editUser={props.editUser}
              deleteUser={props.deleteUser}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
import React, { createContext, useContext, useState } from 'react';

interface ICommentListState {
  expanded: string[];
  selected: string[];
}

interface ICommentsListContext {
  state: ICommentListState;
  setState: React.Dispatch<React.SetStateAction<ICommentListState>>;
}

const defaultState: ICommentListState = {
  expanded: [],
  selected: []
};

const initialState: ICommentsListContext = {
  state: defaultState,
  setState: (): void => {}
};

const CommentsListContext = createContext<ICommentsListContext>(initialState);

export const CommentsListContextProvider = (props: any) => {
  const [state, setState] = useState<ICommentListState>(defaultState);
  
  return (
    <CommentsListContext.Provider value={{state, setState} as ICommentsListContext}>
      {props.children}
    </CommentsListContext.Provider>
  );
}

export const useCommentsListContext = () => useContext(CommentsListContext);
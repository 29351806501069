import axios, { AxiosResponse } from 'axios';
import { User, UserAdminAction, LoadUsersActionTypes } from '../types';
import { Dispatch } from 'react';

const loadUsers = async (dispatch: Dispatch<UserAdminAction>) => {
  try {
    dispatch({type: LoadUsersActionTypes.LOAD_USERS_START});
    const response: AxiosResponse = await axios.get('/api/users');
    dispatch({type: LoadUsersActionTypes.LOAD_USERS_DONE, payload: sortByFirstName(response.data.rows)});
  } catch (error) {
    dispatch({type: LoadUsersActionTypes.LOAD_USERS_ERROR, payload: error.response.data});
  }
}

const sortByFirstName = (users: User[]) => 
  users.sort((a, b) => {
    const aFN = a.firstName === undefined ? '' : a.firstName;
    const bFN = b.firstName === undefined ? '' : b.firstName;
    return aFN.localeCompare(bFN);
  }
);

export default loadUsers;
import React, { useEffect } from 'react';
import {Button, Typography } from '@material-ui/core';
import { styles } from '../styles';
import { LoginAction } from '../types';
import { cancelLogin } from '../actions/cancelLogin';
import { openSecurityApp } from '../actions/openSecurityApp';

export interface ILoginQRCodeProps {
  dispatch: React.Dispatch<LoginAction>;
  autoStartToken: string;
  returnURL?: string;
}

export const LoginSameUnit = (props: ILoginQRCodeProps)  => {
  const classes = styles();
  const { dispatch, autoStartToken, returnURL } = props;

  useEffect(() => {
    openSecurityApp(autoStartToken, returnURL);
  }, [autoStartToken, returnURL]);

  return (
    <>
      <Typography variant='h4' component='h2' className={classes.label}>Logga in med BankID</Typography>
      
      <p className={classes.text}>
        Försöker starta BankID-appen.
      </p>
      
      <div className={classes.buttonColumn}>
      <Button
          type='submit'
          variant='outlined'
          color='primary'
          onClick={() => openSecurityApp(autoStartToken, returnURL)}
        >Öppna BankID säkerhersprogram</Button>
        <Button
          type='submit'
          variant='outlined'
          color='primary'
          onClick={() => cancelLogin(dispatch)}
        >Avbryt</Button>
      </div>
    </>
  );
};
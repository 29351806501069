import { MessageBarProps } from '../general/messageBar/MessageBar';
import { DominoUser } from './actions/getDominoUsers';
export interface User {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  pin?: string;
  pads?: string;
  lads?: string;
  role?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface UserDialogState {
  firstName: string;
  lastName: string;
  email: string;
  pin: string;
  dominoUser?: DominoUser;
}

export interface DeleteUserDialogState {
  show: boolean;
  user: User;
  message: string;
}

export interface UserDialogProps {
  show: boolean;
  user?: User;
  userList: User[];
  onSave: any;
  onClose: any;
}

export interface UserAdminState {
  loading: boolean;
  userList: User[];

  selectedUser?: User;
  dialog: UserDialogProps;
  deleteDialog?: DeleteUserDialogState;
  message?: MessageBarProps;
}

export interface UserAdminAction {
  type: LoadUsersActionTypes | CreateUserActionTypes | UpdateUserActionTypes | DeleteUserActionTypes | string,
  payload?: any
}

export enum LoadUsersActionTypes {
  LOAD_USERS_START = 'LOAD_USERS_START',
  LOAD_USERS_DONE = 'LOAD_USERS_DONE',
  LOAD_USERS_ERROR = 'LOAD_USERS_ERROR'
}

export enum CreateUserActionTypes {
  CREATE_USER_START = 'CREATE_USER_START',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR = 'CREATE_USER_ERROR'
}

export enum UpdateUserActionTypes {
  UPDATE_USER_START = 'UPDATE_USER_START',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
}

export enum DeleteUserActionTypes {
  DELETE_USER_START = 'DELETE_USER_START',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR = 'DELETE_USER_ERROR',
  DELETE_USER_CANCEL = 'DELETE_USER_CANCEL'
}
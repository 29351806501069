import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import JoditEditor, { Jodit } from 'jodit-react';

import { Comment, SaveComment } from '../../types';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Buttons from './Buttons';

interface EditCommentProps {
  comment: Comment;
  onSave: any;
  onClose: any;
  onSaveAndClose: any;
  scrollTo: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(2)
  },
  label: {
    flexGrow: 1
  },
  alert: {
    backgroundColor: 'rgb(252,229,163)',
    color: '#000',
    marginBottom: theme.spacing(2),
    '& svg': {
      color: '#000'
    }
  },
  topButtonRow: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > div:first-child': {
      flexGrow: 1
    }
  },
  bottomButtonRow: {
    display: 'flex',
    marginTop: theme.spacing(1),
    '& > div:first-child': {
      flexGrow: 1
    }
  },
  center: {
    margin: 'auto 0'
  }
}));

let currentContent: string = '';
// eslint-disable-next-line
let changed: boolean = false;

const EditComment = (props: EditCommentProps) => {
  const { comment, onClose , scrollTo } = props;
  const classes = useStyles();
  const editor: MutableRefObject<Jodit | null> = useRef(null);
  const [ content, setContent ] = useState<string>('');
  const commentRef = useRef<HTMLDivElement>(null);

  const getSaveComment = (): SaveComment => {
    setContent(currentContent);
    return {
      caseno: comment.caseno,
      comment: currentContent,
      unid: comment.unid,
      done: false
    };
  }

  const onSave = async () => {
    await props.onSave(getSaveComment());
  }

  const onSaveAndClose = async () => {
    await props.onSaveAndClose(getSaveComment());
  }

  useEffect(()=> {
    currentContent = comment ? comment.comment : '';
    setContent(currentContent);
    changed = false;
    return () => {
      currentContent = '';
      changed = false;
    };
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (scrollTo) {
      if (commentRef && commentRef.current) {
        const node = commentRef.current;
        setTimeout(()=> {
          node.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }, 1000);
      }
    }
  // eslint-disable-next-line
  }, [commentRef]);

  return (
    <div ref={commentRef} className={classes.root}>
      <Alert severity="info" className={classes.alert}>
        <Typography>{'Ange inte personuppgifter (exempelvis namn på patienten).'}</Typography>
      </Alert>
      <div className={classes.topButtonRow}>
        <Buttons onSave={onSave} onClose={onClose} onSaveAndClose={onSaveAndClose}/>
      </div>
      <JoditEditor
        ref={editor}
        value={content}
        config={{
          readonly: false,
          buttons: [
            'bold', 'strikethrough', 'underline', 'italic', 'eraser', '|', 
            'fontsize', 'brush' ,'|', 
            'ul', 'ol', '|', 
            'outdent', 'indent', 'align', '|', 
            'undo', 'redo', '|', 
            'selectall', 'cut', 'copy', 'paste', '|', 
            'fullsize'
          ],
          // showCharsCounter: false,
          // showWordsCounter: false,
          // showXPathInStatusbar: false,
          language: 'sv',
          i18n: {
            sv: {
              'Type something': 'Skriv din kommentar här'
            }
          }
        }}
        
        onBlur={content => currentContent = content}
        onChange={() => changed = true}
      />
      <div className={classes.bottomButtonRow}>
        <div></div>
        <Buttons onSave={onSave} onClose={onClose} onSaveAndClose={onSaveAndClose}/>
      </div>
    </div>
  );
}

export default EditComment;
import axios from 'axios';
import { UserAdminAction, User, DeleteUserActionTypes } from '../types';
import { Dispatch } from 'react';
import loadUsers from './loadUsers';

const deleteUser = async (dispatch: Dispatch<UserAdminAction>, user: User) => {
  try {
    await axios.delete(`/api/users/${user.id}`);
    dispatch({type: DeleteUserActionTypes.DELETE_USER_SUCCESS});
    loadUsers(dispatch);
  } catch (error) {
    dispatch({type: DeleteUserActionTypes.DELETE_USER_ERROR, payload: error.response.data});
  }
}

export default deleteUser;
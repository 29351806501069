import * as constants from '../utils/constants';
import grey from '@material-ui/core/colors/grey';

// eslint-disable-next-line
export default {
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary: {
      main: constants.colors.psnBlueDark,
      dark: constants.colors.psnBlueDark,
      light: constants.colors.psnBlueDark
    },
    secondary: {
      main: constants.colors.psnGreen,
      dark: constants.colors.psnGreen,
      light: constants.colors.psnGreen
    }
  },
  datePicker: {
    headerColor: constants.colors.psnBlueDark
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%'
        },
        body: {
          height: '100%',
          '& > div#root': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }
        }
      }
    },
    MuiButton: {
      contained: {
        color: '#333',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: grey['200']
        }
      }
    }
  }
};

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

import mainTheme from 'themes/main';
import { AuthenticatedContextProvider } from 'hooks/AuthenticatedContext';

ReactDOM.render(
  <ThemeProvider theme={createTheme(mainTheme)}>
    <CssBaseline/>
    <AuthenticatedContextProvider>
      <App />
    </AuthenticatedContextProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { CommentsListContextProvider } from './hooks/CommentsListContext';
import { useAuthenticatedContext } from './hooks/AuthenticatedContext';
import { Header } from './components/general/Header/Header';
import { Footer } from './components/general/Footer/Footer';

import About from './components/about/About';
import Case from './components/case/Case';
import MyCases from './components/mycases/MyCases';
import UserAdmin from './components/useradmin/UserAdmin';
import { GlobalMessageProvider } from './hooks/GlobalMessage';
import { Login2 } from 'components/login2/Login2';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column'
  }})
);

const App = () => {
  const {authenticated} = useAuthenticatedContext();
  const classes = useStyles();

  if (authenticated.isAuthenticated) {
    return (
      <GlobalMessageProvider>
        <CommentsListContextProvider>
          <Router>
            <Header/>
            <Container component="main" maxWidth="lg" className={classes.container}>
              <Switch>
                <Route path="/about">
                  <About/>
                </Route>
                <Route path="/users">
                  <UserAdmin/>
                </Route>
                <Route path="/case/:year/:no" render={(props) => {
                  const caseno = `${props.match.params.year}/${props.match.params.no}`;
                  return (
                    <Case caseno={caseno}/>
                  );
                }}/>
                <Route path="/">
                  <MyCases/>
                </Route>
              </Switch>
            </Container>
            <Footer />
          </Router>
        </CommentsListContextProvider>
      </GlobalMessageProvider>
    );
  } else {
    return (
      <Router>
        <Header/>
        <Container component="main" maxWidth="lg" className={classes.container}>
          <Switch>
            <Route path="/about">
              <About/>
            </Route>
            <Route path='*'>
              <Login2/>
            </Route>
          </Switch>
        </Container>
        <Footer />
      </Router>
    );
  }
}

export default App;
